.fos-region-overlay{
    position: absolute;
    z-index: 450;
    visibility: visible;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.fos-region-overlay-fixed{
    position: fixed;
    z-index: 450;
    visibility: visible;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}

